<template>
<form @submit.prevent="">
    
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان ویجت" id="title" v-model="data.title"></v-text-field>
        <div class="col-12" v-if="!isMobile">
            <input type="text" id="title" placeholder="عنوان ویجت" class="widget-input input-no-bg input-lg input-set-border w-100" v-model="data.title">
        </div>
    </div>
    <div class="form-row">
        <v-select v-if="isMobile" v-model="data.selected_watchList" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="data.watchLists" item-text="title"  item-value="id" label="انتخاب لیست" outlined></v-select>
        <div class="col-12" v-if="!isMobile">
            <div class="widget-select w-100 m-top widget-select-lg">
                <v-select2 v-model="data.selected_watchList" :clearable="false" label="title" :reduce="(option) => option.id" :options="data.watchLists">
                </v-select2>
            </div>
        </div>
    </div>

    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveSettings()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مربوط به محتوای ویجت های تنظیمات (واچ لیست ها) است
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'SettingsWatchList',
    components: {},
    props: ['data'],
    data: function () {
        return {
            isMobile:false,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.data.watchLists.push({
                id: 0,
                title: "انتخاب کنید"
            });
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت ارسال اطلاعات داخل مودال به کلاس یا واسط پرنت می باشد(همون کال باک های معروف)
        saveSettings() {
            let data = this.$helpers.unbindObject(this.data);
            this.$parent.saveSettings({
                title: this.data.title,
                data: {
                    selected_watchList: this.data.selected_watchList
                },
            });
        },
    },
}
</script>
